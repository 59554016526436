import type StateCore from 'markdown-it/lib/rules_core/state_core.mjs';
// eslint-disable-next-line @atlassian/tangerine/import/entry-points
import type { Nesting, default as Token } from 'markdown-it/lib/token.mjs';

export function createToken(state: StateCore, type: string, level?: number): Token {
	let nesting: Nesting;
	if (type.includes('_open')) {
		nesting = 1;
	} else if (type.includes('_close')) {
		nesting = -1;
	} else {
		nesting = 0;
	}
	const token = new state.Token(type, '', nesting);
	if (level) {
		token.level = level;
	}
	return token;
}

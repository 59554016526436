import type MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core.mjs';
import type Token from 'markdown-it/lib/token.mjs';

import { createToken } from '../utils/create-token';

// eslint-disable-next-line require-unicode-regexp
const mediaRegex = /(!\[[^\]]*\]\([^)]+\))|(\[!\[[^\]]*\]\([^)]+\)\]\([^)]+\))/gu;

const getUrl = (state: StateCore, str: string) => {
	const res = state.md.helpers.parseLinkDestination(str, str.indexOf('(') + 1, str.length);
	if (res.ok) {
		const href = state.md.normalizeLink(res.str);
		if (state.md.validateLink(href)) {
			return href;
		}
	}

	return '';
};

const checkPreviousToken = (type: string, acc: Token[]) => {
	return acc.length > 0 && acc[acc.length - 1].type === type;
};

const createMediaTokens = (url: string, state: StateCore, acc: Token[]) => {
	const mediaSingleOpen = createToken(state, 'media_single_open');
	const media = createToken(state, 'media');

	media.attrs = [
		['url', getUrl(state, url)],
		['type', 'external'],
	];

	// This is a hack to get internal media to work
	// to recreate the image correctly we need to apply the pmAttrs from the image
	// We store the pmAttrs in the "fake" image url and apply them to the token
	if (url.includes('atlassianinternalmedia')) {
		try {
			const urlObject = new URL(getUrl(state, url));
			const params = urlObject.searchParams;

			if (params.toString()) {
				const pmAttrs: { [key: string]: string } = {};
				for (const [key, value] of params) {
					pmAttrs[key] = value;
				}

				// @ts-expect-error - pmAttrs is not a standard markdown-it attribute
				media.pmAttrs = pmAttrs;
			}
		} catch {}
	}
	const mediaSingleClose = createToken(state, 'media_single_close');

	acc.push(mediaSingleOpen, media, mediaSingleClose);
};

const createInlineTokens = (content: string, state: StateCore, acc: Token[]) => {
	const paragraphOpen = createToken(state, 'paragraph_open');
	const inline = createToken(state, 'inline');
	inline.content = content;
	inline.children = [createToken(state, 'text')];
	const paragraphClose = createToken(state, 'paragraph_close');

	acc.push(paragraphOpen, inline, paragraphClose);
};

/**
 * Splits the content of the inline token
 * Inline tokens can contain multiple media elements as well as regular text
 * We split the content into an array of strings which can then be converted into the correct tokens
 */
const findContent = (token: Token) => {
	let content = [];
	let lastIndex = 0;
	let match;

	mediaRegex.lastIndex = 0;

	// eslint-disable-next-line no-cond-assign
	while ((match = mediaRegex.exec(token.content)) !== null) {
		const matchedString = match[0];
		const matchIndex = match.index;

		if (lastIndex < matchIndex) {
			content.push(token.content.substring(lastIndex, matchIndex));
		}

		content.push(matchedString);

		lastIndex = matchIndex + matchedString.length;
	}

	if (lastIndex < token.content.length) {
		content.push(token.content.substring(lastIndex));
	}

	content = content.filter((item) => item.length > 0);
	return content;
};

export default function (md: MarkdownIt) {
	md.core.ruler.before('inline', 'media', function (state: StateCore) {
		state.tokens = state.tokens.reduce((acc: Token[], token, index) => {
			const { type } = token;
			if (type === 'inline' && mediaRegex.test(token.content)) {
				// If the previous token is a paragraph_open we want to remove it
				if (checkPreviousToken('paragraph_open', acc)) {
					acc.pop();
				}

				const content = findContent(token);

				content.forEach((item) => {
					const doesMatch = /!\[[^\]]*\]\([^)]+\)/u.test(item);
					if (doesMatch) {
						createMediaTokens(item, state, acc);
					} else {
						createInlineTokens(item, state, acc);
					}
				});

				// If the current token is paragraph close we want to make sure that the previous token isn't a media_single_close
				// or paragraph_close
			} else if (type === 'paragraph_close') {
				if (
					!checkPreviousToken('media_single_close', acc) &&
					!checkPreviousToken('paragraph_close', acc)
				) {
					acc.push(token);
				}
			} else {
				acc.push(token);
			}

			return acc;
		}, []);
	});
}

import type MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core.mjs';
import type Token from 'markdown-it/lib/token.mjs';

import type { IDMap } from '../../../convert-prosemirror-to-markdown/pm-markdown/serializer';

const decisionItemOpen = 'decisionItem_open';
const paragraphOpen = 'paragraph_open';
const paragraphClose = 'paragraph_close';
const inline = 'inline';

function handleParagraphs(acc: Token[], token: Token) {
	/**
	 * Traverse up the acc token array, and if a chain of one or more inline tokens are under
	 * a decisionItem_open token, then it is considered to be a paragraph_close token within
	 * a decision item.
	 *
	 * This also works for a paragraph_open token as inlineCount would be zero meaning that
	 * the conditional applies to when decisionItem_open is right above paragraph_open.
	 */

	let inlineCount = 0;
	let inlineChainEnded = false;

	for (let i = acc.length - 1; i >= 0; i--) {
		const type = acc[i]?.type;

		if (!inlineChainEnded) {
			if (type === inline) {
				inlineCount += 1;
			} else {
				inlineChainEnded = true;
			}
		}
	}

	const isWithinDecisionItem = acc[acc.length - (inlineCount + 1)]?.type === decisionItemOpen;

	if (!isWithinDecisionItem) {
		acc.push(token);
	}
}

export default function (md: MarkdownIt, idMap: IDMap) {
	md.core.ruler.after('custom-block', 'decision-list', function (state: StateCore) {
		state.tokens = state.tokens.reduce((acc: Token[], token: Token, index: number) => {
			const { type } = token;

			if (type === paragraphOpen || type === paragraphClose) {
				handleParagraphs(acc, token);
			} else {
				acc.push(token);
			}
			return acc;
		}, []);
	});
}

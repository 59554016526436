// File copied from packages/editor/editor-core/src/plugins/hyperlink/utils.ts

// Regular expression for a windows filepath in the format <DRIVE LETTER>:\<folder name>\
// Ignored via go/ees005
// eslint-disable-next-line require-unicode-regexp
export const FILEPATH_REGEXP = /([a-zA-Z]:|\\)([^\/:*?<>"|]+\\)?([^\/:*?<>"| ]+(?=\s?))?/gim;

interface filepathMatch {
	startIndex: number;
	endIndex: number;
}

export const findFilepaths = (text: string, offset: number = 0): Array<filepathMatch> => {
	// Creation of a copy of the RegExp is necessary as lastIndex is stored on it when we run .exec()
	// Ignored via go/ees005
	// eslint-disable-next-line require-unicode-regexp
	const localRegExp = new RegExp(FILEPATH_REGEXP);
	let match;
	const matchesList = [];
	const maxFilepathSize = 260;
	// Ignored via go/ees005
	// eslint-disable-next-line no-cond-assign
	while ((match = localRegExp.exec(text)) !== null) {
		const start = match.index + offset;
		let end = localRegExp.lastIndex + offset;
		if (end - start > maxFilepathSize) {
			end = start + maxFilepathSize;
		} // We don't care about big strings of text that are pretending to be filepaths!!
		matchesList.push({
			startIndex: start,
			endIndex: end,
		});
	}
	return matchesList;
};

export const isLinkInMatches = (linkStart: number, matchesList: Array<filepathMatch>): boolean => {
	for (let i = 0; i < matchesList.length; i++) {
		if (linkStart >= matchesList[i].startIndex && linkStart < matchesList[i].endIndex) {
			return true;
		}
	}
	return false;
};

// @see https://product-fabric.atlassian.net/browse/ED-3159
// @see https://github.com/markdown-it/markdown-it/issues/38
export function escapeLinks(text: string) {
	return text.replace(
		// Ignored via go/ees005
		// eslint-disable-next-line require-unicode-regexp
		/(\[([^\]]+)\]\(|\!\[([^\]]*)\]\()?((https?|ftp|jamfselfservice):\/\/[^\s]+)/g,
		(str) => {
			// Ignored via go/ees005
			// eslint-disable-next-line require-unicode-regexp
			return str.match(/^(https?|ftp|jamfselfservice):\/\/[^\s]+$/) ? `<${str}>` : str;
		},
	);
}

import type { Token } from 'markdown-it';
import type MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core.mjs';

import type { IDMap } from '../../../convert-prosemirror-to-markdown/pm-markdown/serializer';

const captionOpen = 'caption_open';
const paragraphOpen = 'paragraph_open';
const paragraphClose = 'paragraph_close';

export function handleParagraphs(index: number, tokens: Token[]) {
	// make sure we have an opening and closing paragraph token
	if (tokens[index]?.type !== paragraphOpen && tokens[index + 2]?.type !== paragraphClose) {
		return;
	}
	// remove the closing paragraph token from state.tokens so we don't add it into the acc
	// when we loop through the tokens
	tokens[index + 2]?.type === paragraphClose && tokens.splice(index + 2, 1);
	// return empty to prevent adding the opening token to the acc
	return;
}

export default function (md: MarkdownIt, idMap: IDMap) {
	md.core.ruler.after('custom-block', 'caption', function (state: StateCore) {
		state.tokens = state.tokens.reduce((acc: Token[], token: Token, index: number) => {
			const { type } = token;
			const isWithinCaption = acc[acc.length - 1]?.type === captionOpen;
			if (type === paragraphOpen && isWithinCaption) {
				/**
				 * paragraph_open and paragraph_close tokens get automatically added by markdown-it
				 * around text content. caption nodes do not allow paragraph nodes as children
				 * so we need to prevent these tokens from being added to the acc
				 */
				handleParagraphs(index, state.tokens);
			} else {
				acc.push(token);
			}
			return acc;
		}, []);
	});
}
